import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import * as queryString from 'query-string'
import ModalLayout from '../components/Modals/ModalLayout'
import Icon from '../images/forms/ben-sm.png'
import FormLeftContent from '../components/Forms/FormLeftContent'

import { appendUrlPartnerTracking } from '../util/url'
import Button from '../components/shared/Button/Button'
import checks from '../buildAssets/compliance/tick.svg'

import { LandingPageBookADemoFormRightContent } from "../components/Forms/GetInTouch/LandingPageBookADemoFormRight"
import Quote from '../components/Quote/Quote'
import { useLocalStorage } from '../util/useLocalStorage'
import { InPageBot } from "@leadoo/react-bot";

const ContactUs = ({ location }) => {


  const heading = "Let’s talk!"
  const body = "Want to chat with our team, or have a question about our products? You're in the right place. Simply interact with the bot on this page, and we'll help you with whatever it is you require. Prefer a call? You can reach us at 01226 337335"
  const employeeName = "Ben"
  const employeeTitle = "Head of Sales and Partnerships"

  /* HUBSPOT ONLY
  const [success, setSuccess] = useState(false)



  useEffect(() => {
    const { full_name } = queryString.parse(location.search)
    const { refreshed } = queryString.parse(location.search)
    if (typeof window !== 'undefined') {
      if (refreshed === undefined && full_name === undefined) {

        window.location.href = window.location.href + "?refreshed=true"

      }
    }
    if (full_name !== undefined) {
      setSuccess(true)
    } else {
      setSuccess(false)
    }

  }, [])
  let rightContent = <LandingPageBookADemoFormRightContent />
  if (success) {
    rightContent = <div>
      <SuccessImage src={checks} />
      <HeadingSuccess>
        Thank you! We've received your demo request.
      </HeadingSuccess>
      <SubHeadingSuccess>
        You'll receive an email confirmation in your inbox in a few moments, followed by a message from one of our experts. Enquiries submitted outside of working hours will be answered on the next working day.
      </SubHeadingSuccess>
      <a style={{ maxWidth: 165 }} href={appendUrlPartnerTracking("/")}>
        <Button href={appendUrlPartnerTracking("/")}>Done</Button>
      </a>
    </div>
  }*/
  var rightContent;
  if (typeof window !== 'undefined' && window.location.href.includes("bobsbusiness.co.uk")){
    rightContent = <InPageBot code="78IZnZ8Y" />;
  } else {
    rightContent = <InPageBot code="a3MzmOgO" />;
  }

  return (
    <>
      <ModalLayout
        type={'Book a Demo'}
        body={'Body'}
        leftContent={<FormLeftContent data={{ heading: heading, body: body, employeeTitle: employeeTitle, employeeName: employeeName, icon: Icon }} />}
        rightContent={rightContent}
      />
    </>
  )
}

const Tag = styled.p`
  font-size: 13px;
  line-height: 21px;
  font-weight: 400;
  > a {
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
  }
`

const HeadingSuccess = styled.h2`
  font-size: 32px;
  line-height: 40px;
`
const SubHeading = styled.h5`
  padding: 0px 40px 41px;
  font-weight: 300;
`
const SubHeadingSuccess = styled(SubHeading)`
  padding: 0px 0px 25px;
`

const SuccessImage = styled.img`
  margin-top: 35px;
  width: 90px;
  height: 90px;
  margin-bottom: 35px;
`


export default ContactUs

